import Image from 'next/image';
import { useRef, useState } from 'react';

import { ViewEditCTAPair } from '@/components/atomic/atoms';
import {
  Button,
  HamburgerMenu,
  SectionHeader
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { useIsMobile } from '@/lib/screenResolution';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const EventCartDetailHeader = ({
  backward,
  eventId,
  forward,
  heading,
  headingStyle,
  type,
  userId
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const [isMobile] = useIsMobile();

  const redirectToMetabasePlannerBoard = () =>
    window.open(
      getPageURL({
        excludeBaseUrl: false,
        pageName: PAGE_NAME.METABASE_PLANNER_BOARD.label,
        queryParams: {
          event_id: eventId,
          user_id: userId
        }
      }),
      '_blank'
    );

  const menuOptions = [
    {
      alt: 'View planner board',
      hoverIcon: 'view-icon-with-frame-red.svg',
      icon: 'view-gray-icon-v1.svg',
      label: 'View Planner Board',
      onClick: redirectToMetabasePlannerBoard
    },
    {
      alt: 'Import cart',
      hoverIcon: 'download-brand-icon.svg',
      icon: 'download-black-icon.svg',
      isInactive: forward.disabled,
      label: 'Import Cart',
      onClick: () => forward.onClick()
    }
  ];

  const hamburgerMenuRef = useRef(null);
  useOnClickOutside(hamburgerMenuRef, () => setShowMenu(false));

  return (
    <div className='flex justify-between my-6'>
      <div className='justify-start self-center flex gap-5'>
        <SectionHeader
          {...{
            heading,
            headingStyle
          }}
        />
      </div>
      {isMobile ? (
        <div
          className='flex gap-4 justify-end'
          ref={hamburgerMenuRef}
        >
          <Image
            {...{
              alt: 'add new cart',
              className: `cursor-pointer`,
              height: 0,
              onClick: () => backward.onClick(),
              src: `${staticMediaStoreBaseURL}/icons/add-new-cart.svg`,
              style: { height: 36, width: 36 },
              width: 0
            }}
          />
          <HamburgerMenu
            {...{
              icon: { height: 36, width: 36 },
              menuContainerWidth: 'max',
              menuOptions,
              setShowMenu,
              showMenu
            }}
          />
        </div>
      ) : (
        <div className='flex gap-4'>
          <Button
            {...{
              className:
                'py-3 px-4 text-sm font-medium self-end rounded-lg border border-brand text-brand bg-white',
              label: 'View Planner Board',
              onClick: redirectToMetabasePlannerBoard,
              width: 'w-auto'
            }}
          />
          <ViewEditCTAPair
            {...{
              backward,
              containerClass: '',
              forward,
              type
            }}
          />
        </div>
      )}
    </div>
  );
};

export default EventCartDetailHeader;
