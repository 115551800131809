import _ from 'lodash';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  EventProfileStep,
  StepFormHeader,
  TerminateEventStep,
  ViewEditCTAPair
} from '@/components/atomic/atoms';
import {
  AddressModal,
  EventDeepDiveStep,
  HostProfileStep
} from '@/components/atomic/molecules';
import { TextWithIcon } from '@/components/atomic/nuclei';
import {
  DEVICE_SURFACE,
  HOST_NAME_VALIDATION_CONFIG,
  NOTIFICATION_TYPE,
  staticMediaStoreBaseURL
} from '@/config/common';
import usePageExit from '@/hooks/usePageExit';
import useYupValidator from '@/hooks/useYupValidator';
import { transformToNumberValue } from '@/lib/numberStringUtils';
import { GetUiDeviceSurface } from '@/lib/screenResolution';
import {
  checkStepHasErrors,
  EVENT_PROFILE_FORM_STEPS,
  eventProfileStepFieldErrorConfig,
  submitUserEventProfile,
  userAddressObject
} from '@/services/hostEvent.service';

const { nameRegex, nameInputError } = HOST_NAME_VALIDATION_CONFIG;

const HostEventProfileBodyWeb = ({
  clearErrors,
  control,
  errors,
  eventFormDetails,
  eventSiteTypeList,
  eventVerticals,
  formActiveStepId,
  getValues,
  isUserEventDetailInEditMode,
  leadExitReasons,
  onClickExit,
  register,
  selectedEventRequestVerticals,
  setEventFormDetails,
  setFormActiveStepId,
  setSelectedEventRequestVerticals,
  setValue
}) => (
  <div className='w-full mt-4 md:mt-0'>
    <div className='w-full rounded-2.5 mb-20'>
      <StepFormHeader
        {...{
          errors,
          fieldsErrorConfig: eventProfileStepFieldErrorConfig,
          formActiveStepId,
          onClickExit,
          setFormActiveStepId,
          showCloseIcon: !isUserEventDetailInEditMode,
          tabList: Object.values(EVENT_PROFILE_FORM_STEPS)
        }}
      />

      <div className='px-6'>
        <EventProfileStep
          {...{
            clearErrors,
            control,
            errors,
            eventFormDetails,
            eventVerticals,
            formActiveStepId,
            getValues,
            isUserEventDetailInEditMode,
            register,
            selectedEventRequestVerticals,
            setEventFormDetails,
            setSelectedEventRequestVerticals,
            setValue
          }}
        />
        <HostProfileStep
          {...{
            errors,
            formActiveStepId,
            getValues,
            isUserEventDetailInEditMode,
            register
          }}
        />
        <EventDeepDiveStep
          {...{
            contactMethods: eventFormDetails.contactMethods,
            errors,
            eventFormDetails,
            eventSiteTypeList,
            formActiveStepId,
            getValues,
            isUserEventDetailInEditMode,
            register,
            selectedEventContactMethod:
              eventFormDetails.selectedEventContactMethod,
            setEventFormDetails,
            setValue
          }}
        />
        <TerminateEventStep
          {...{
            clearErrors,
            control,
            errors,
            formActiveStepId,
            getValues,
            isUserEventDetailInEditMode,
            leadExitReasons,
            register,
            setValue
          }}
        />
      </div>
    </div>
  </div>
);

const notificationConfigEnum = {
  [NOTIFICATION_TYPE.ERROR]: {
    icon: 'error-fill-icon.svg'
  },
  [NOTIFICATION_TYPE.SUCCESS]: {
    icon: 'check-fill-green-icon.svg'
  }
};

const Notification = ({
  notificationConfig: {
    errors,
    toastConfig: { message, show: showToast, successToast: toastStatus }
  }
}) => {
  const formHasNoErrors = _.isEmpty(errors);

  const notificationType =
    formHasNoErrors && toastStatus
      ? NOTIFICATION_TYPE.SUCCESS
      : NOTIFICATION_TYPE.ERROR;

  const messageToDisplay = formHasNoErrors
    ? message
    : 'Unable to save. Please review form for errors';

  const { icon } = notificationConfigEnum[notificationType];

  return (
    <div className='bg-black text-sm font-medium flex gap-3 justify-center rounded-lg fixed opacity-80 left-1/2 w-80 toaster bottom-[6rem]'>
      <TextWithIcon
        {...{
          className: 'flex gap-2 items-center py-3 px-4',
          icon,
          label: messageToDisplay,
          labelStyle: 'text-white font-medium',
          show: Boolean(showToast)
        }}
      />
    </div>
  );
};

const HostEventProfileBodyMobile = ({
  clearErrors,
  control,
  errors,
  eventFormDetails,
  eventSiteTypeList,
  eventVerticals,
  getValues,
  isUserEventDetailInEditMode,
  leadExitReasons,
  register,
  selectedEventRequestVerticals,
  setEventFormDetails,
  setSelectedEventRequestVerticals,
  setValue
}) => {
  const [openIndex, setOpenIndex] = useState(null);

  const accordionItems = [
    {
      brandIcon: 'user-event-brand-icon.svg',
      content: (
        <EventProfileStep
          {...{
            clearErrors,
            control,
            errors,
            eventFormDetails,
            eventVerticals,
            formActiveStepId: EVENT_PROFILE_FORM_STEPS.BASIC_EVENT_PROFILE.id,
            getValues,
            isUserEventDetailInEditMode,
            register,
            selectedEventRequestVerticals,
            setEventFormDetails,
            setSelectedEventRequestVerticals,
            setValue
          }}
        />
      ),
      stepId: EVENT_PROFILE_FORM_STEPS.BASIC_EVENT_PROFILE.id,
      title: 'Event Profile',
      whiteIcon: 'user-event-white-icon.svg'
    },
    {
      brandIcon: 'host-brand-v1-icon.svg',
      content: (
        <HostProfileStep
          {...{
            errors,
            formActiveStepId: EVENT_PROFILE_FORM_STEPS.HOST_PROFILE.id,
            getValues,
            isUserEventDetailInEditMode,
            register
          }}
        />
      ),
      stepId: EVENT_PROFILE_FORM_STEPS.HOST_PROFILE.id,
      title: 'Host Profile',
      whiteIcon: 'host-white-icon.svg'
    },
    {
      brandIcon: 'event-deep-dive.svg',
      content: (
        <EventDeepDiveStep
          {...{
            contactMethods: eventFormDetails.contactMethods,
            errors,
            eventFormDetails,
            eventSiteTypeList,
            formActiveStepId: EVENT_PROFILE_FORM_STEPS.EVENT_DEEP_DIVE.id,
            getValues,
            isUserEventDetailInEditMode,
            register,
            selectedEventContactMethod:
              eventFormDetails.selectedEventContactMethod,
            setEventFormDetails,
            setValue
          }}
        />
      ),
      stepId: EVENT_PROFILE_FORM_STEPS.EVENT_DEEP_DIVE.id,
      title: 'Event Deep Dive',
      whiteIcon: 'event-deep-dive-white-icon.svg'
    },
    {
      brandIcon: 'terminate-event-v1-icon.svg',
      content: (
        <TerminateEventStep
          {...{
            clearErrors,
            control,
            errors,
            formActiveStepId: EVENT_PROFILE_FORM_STEPS.TERMINATE.id,
            getValues,
            isUserEventDetailInEditMode,
            leadExitReasons,
            register,
            setValue
          }}
        />
      ),
      stepId: EVENT_PROFILE_FORM_STEPS.TERMINATE.id,
      title: 'Terminate Event',
      whiteIcon: 'terminate-event-white-icon.svg'
    }
  ];

  const notificationConfig = {
    errors,
    toastConfig: {
      message: eventFormDetails.toastMessage,
      show: Boolean(eventFormDetails.toastMessage) || !_.isEmpty(errors),
      successToast: eventFormDetails.toastStatus
    }
  };

  return (
    <div className='max-w-md mx-auto space-y-2'>
      {accordionItems.map(
        ({ brandIcon, content, title, whiteIcon, stepId }, index) => {
          const currentIndex = openIndex === index;

          const stepHasErrors = checkStepHasErrors({
            fieldsErrorConfig: eventProfileStepFieldErrorConfig,
            errors,
            stepId
          });

          return (
            <div
              key={index}
              className='border border-brand rounded-xl shadow-sm'
            >
              <div
                className={`w-full flex justify-between items-center p-4 cursor-pointer ${currentIndex ? 'rounded-t-xl bg-brand-gradient' : ''}`}
                onClick={() => setOpenIndex(currentIndex ? null : index)}
              >
                <div className='flex items-center space-x-3'>
                  <Image
                    {...{
                      alt: title,
                      height: 0,
                      src: `${staticMediaStoreBaseURL}/icons/${currentIndex ? whiteIcon : brandIcon}`,
                      style: { height: 24, width: 24 },
                      width: 0
                    }}
                  />
                  <span
                    className={`font-medium text-base ${currentIndex ? 'text-white' : ''}`}
                  >
                    {title}
                  </span>
                  {stepHasErrors && (
                    <Image
                      {...{
                        alt: 'error',
                        height: 0,
                        src: `${staticMediaStoreBaseURL}/icons/error-fill-icon.svg`,
                        style: { height: 16, width: 16 },
                        width: 0
                      }}
                    />
                  )}
                </div>
                <Image
                  {...{
                    alt: currentIndex ? 'Collapse' : 'Expand',
                    height: 0,
                    src: `${staticMediaStoreBaseURL}/icons/${currentIndex ? 'arrow-down-white-icon.svg' : 'right-angle-red.svg'}`,
                    style: { height: 16, width: 16 },
                    width: 0
                  }}
                />
              </div>
              {currentIndex && <div>{content}</div>}
            </div>
          );
        }
      )}
      <Notification {...{ notificationConfig }} />
    </div>
  );
};

const HostEventProfileOrganism = ({
  checkoutEvents,
  cityList,
  contactMethods,
  eventSiteTypeList,
  eventVerticals,
  leadExitReasons,
  setUserEventInformation,
  userEventInformation,
  userInfo
}) => {
  const {
    contactMethodId,
    leadExitReason,
    userEventDetails: [userEventDetails],
    zendeskTicketDetail = {},
    zendeskTicketId
  } = userEventInformation;
  const {
    budget,
    eventAddress,
    eventDate,
    eventSiteTypeId,
    eventStartTime,
    eventVerticals: selectedEventVerticals,
    expectedGuestCount,
    opportunityValue,
    opsUser
  } = userEventDetails;

  const [isUserEventDetailInEditMode, setUserEventDetailEditMode] =
    useState(false);
  const [formActiveStepId, setFormActiveStepId] = useState(
    EVENT_PROFILE_FORM_STEPS.BASIC_EVENT_PROFILE.id
  );

  const [eventFormDetails, setEventFormDetails] = useState({
    checkoutEvents,
    cityList,
    contactMethods,
    eventSiteTypeList,
    eventVerticals,
    opsUsersList: opsUser?.name ?? '',
    selectedEventContactMethod: contactMethodId,
    selectedEventDate: eventDate ? new Date(eventDate) : null,
    selectedEventSiteType: eventSiteTypeId,
    selectedEventStartTime: eventStartTime?.slice(0, 5) || '',
    showAddAddress: false,
    showAddressError: false,
    toastMessage: '',
    toastStatus: false,
    userAddress: eventAddress || userAddressObject,
    userEventId: userEventInformation?.id,
    zendeskTicketId: zendeskTicketId ?? ''
  });

  const [selectedEventRequestVerticals, setSelectedEventRequestVerticals] =
    useState(selectedEventVerticals.map(({ id }) => id));

  const {
    clearErrors,
    control,
    formState: { errors },
    setError,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue
  } = useForm({
    resolver: useYupValidator(
      yup.object().shape({
        eventContactName: yup
          .string()
          .transform((key, val) => val || '')
          .trim()
          .matches(nameRegex, nameInputError)
          .required('Contact Name is mandatory'),
        eventContactEmail: yup
          .string()
          .trim()
          .transform((key, val) => (val ? val.toLowerCase() : ''))
          .email()
          .required('Contact Email is required'),
        // eslint-disable-next-line newline-per-chained-call
        eventTitle: yup.string().trim().nullable().notRequired(),
        opportunityValue: yup
          .number()
          .positive()
          .transform(transformToNumberValue)
          .nullable(true)
          .notRequired(),
        budget: yup
          .number()
          .positive()
          .nullable(true)
          .notRequired()
          .transform(transformToNumberValue),
        expectedGuestCount: yup
          .number()
          .nullable(true)
          .notRequired()
          .transform(transformToNumberValue),
        eventDurationInDays: yup
          .number()
          .positive()
          .transform(transformToNumberValue)
          .min(1, 'Event Duration should be a minimum 1 (in days)')
          .required('Event Duration is required.'),
        checkoutEventId: yup
          .string()
          .trim()
          .required('Event Type is mandatory'),
        // eslint-disable-next-line newline-per-chained-call
        eventDate: yup.string().trim().required('Event date is mandatory')
      })
    ),
    defaultValues: {
      eventContactEmail: userInfo.email || '',
      eventContactName: userInfo.name || '',
      ...(userEventDetails || {}),
      ...zendeskTicketDetail,
      ...(userEventInformation?.user || {}),
      budget: budget || '',
      eventDurationInDays: userEventInformation?.eventDurationInDays,
      expectedGuestCount,
      leadExitReasonId: leadExitReason?.id || null,
      opportunityValue: opportunityValue || '',
      zendeskTicketId
    }
  });

  const addAddressToggler = () =>
    setEventFormDetails((prevState) => ({
      ...prevState,
      showAddAddress: !prevState.showAddAddress
    }));

  const onClickExit = usePageExit();

  const onCancelClick = () => {
    reset();
    setEventFormDetails((prevState) => ({
      ...prevState,
      selectedEventContactMethod: contactMethodId,
      selectedEventSiteType: eventSiteTypeId
    }));
    setSelectedEventRequestVerticals(
      selectedEventVerticals.map(({ id }) => id)
    );
    setUserEventDetailEditMode(false);
  };

  useEffect(() => {
    const isToastMessageAvailable = Boolean(eventFormDetails.toastMessage);

    isToastMessageAvailable &&
      setTimeout(
        () =>
          setEventFormDetails((prevState) => ({
            ...prevState,
            toastMessage: ''
          })),
        15000
      );
  }, [eventFormDetails.toastMessage]);

  const onUpdateUserEventProfile = () =>
    handleSubmit((data) =>
      submitUserEventProfile({
        data,
        eventFormDetails,
        reset,
        selectedEventRequestVerticals,
        setError,
        setEventFormDetails,
        setFormActiveStepId,
        setUserEventDetailEditMode,
        setUserEventInformation,
        setValue,
        userInfo
      })
    );

  const onSubmitHandler = isUserEventDetailInEditMode
    ? onUpdateUserEventProfile()
    : () => setUserEventDetailEditMode(true);

  const modeType = isUserEventDetailInEditMode ? 'editCTAPair' : 'viewCTAPair';

  const surface = GetUiDeviceSurface();

  const HostEventProfileBodyMap = {
    [DEVICE_SURFACE.MOBILE]: HostEventProfileBodyMobile,
    [DEVICE_SURFACE.WEB]: HostEventProfileBodyWeb
  };

  const notificationConfig = {
    errors,
    toastConfig: {
      message: eventFormDetails.toastMessage,
      show:
        surface === DEVICE_SURFACE.WEB
          ? Boolean(eventFormDetails.toastMessage) || !_.isEmpty(errors)
          : false,
      successToast: eventFormDetails.toastStatus
    }
  };

  const HostEventProfileBody = HostEventProfileBodyMap[surface];

  return (
    <div className='bg-white md:mt-0 mt-5 md:mb-6 md:pl-6 md:pb-10 pb-[5.5rem]'>
      <ViewEditCTAPair
        {...{
          backward: {
            onClick: () =>
              isUserEventDetailInEditMode ? onCancelClick() : onClickExit()
          },
          forward: {
            show: true,
            onClick: onSubmitHandler
          },
          notificationConfig,
          type: modeType
        }}
      />
      <HostEventProfileBody
        {...{
          clearErrors,
          control,
          errors,
          eventFormDetails,
          eventSiteTypeList,
          eventVerticals,
          formActiveStepId,
          getValues,
          isUserEventDetailInEditMode,
          leadExitReasons,
          onClickExit,
          register,
          selectedEventRequestVerticals,
          setEventFormDetails,
          setFormActiveStepId,
          setSelectedEventRequestVerticals,
          setValue
        }}
      />
      {
        // TODO: Hardik -- move this inside Event Deep Dive FormStep.
      }
      {eventFormDetails.showAddAddress && (
        <AddressModal
          {...{
            addAddressToggler,
            cityList: eventFormDetails.cityList,
            handleSubmit,
            setShowAddressError: (addressError) =>
              setEventFormDetails((prevState) => ({
                ...prevState,
                showAddressError: addressError
              })),
            setUserAddress: (address) =>
              setEventFormDetails((prevState) => ({
                ...prevState,
                userAddress: address
              })),
            userAddress: eventFormDetails.userAddress
          }}
        />
      )}
    </div>
  );
};

export default HostEventProfileOrganism;
