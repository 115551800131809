import { EPMFormRow } from '@/components/atomic/atoms';
import { InputField } from '@/components/atomic/nuclei';
import { useIsMobile } from '@/lib/screenResolution';
import { EVENT_PROFILE_FORM_STEPS } from '@/services/hostEvent.service';
import { checkIsEmptyOrDummyEventDetail } from '@/services/userEventCartList.service';

const getCustomerTypeAndPhone = ({
  errors,
  isMobile,
  isUserEventDetailInEditMode,
  register
}) => [
  {
    alt: 'host type icon',
    className: 'rounded-lg pl-10',
    dbName: 'hostSegment.name',
    disabled: true,
    errors,
    iconClass: isMobile ? 'h-10 place-content-center !top-0' : '',
    iconHeight: isMobile ? 20 : 24,
    iconWidth: isMobile ? 20 : 24,
    iconURL: isUserEventDetailInEditMode
      ? 'host-type-brand-v1-icon.svg'
      : 'host-type-gray-icon.svg',
    inputGroup: 'flex flex-col gap-2 flex-1',
    label: 'Host Type',
    labelClass: 'text-nero md:text-base text-sm font-medium',
    placeholder: 'Enter Host Type',
    register: register('hostSegment.name')
  },
  {
    className: 'rounded-lg pl-10',
    dbName: 'mobile',
    disabled: true,
    errors,
    iconClass: isMobile ? 'h-10 place-content-center !top-0' : '',
    iconHeight: isMobile ? 20 : 24,
    iconWidth: isMobile ? 20 : 24,
    iconURL: isUserEventDetailInEditMode
      ? 'phone-brand-icon.svg'
      : 'phone-black-icon.svg',
    inputGroup: 'flex flex-col gap-2 flex-1',
    label: 'Phone Number *',
    labelClass: 'text-nero md:text-base text-sm font-medium',
    placeholder: 'Enter Phone Number',
    register: register('mobile')
  }
];

const HostProfileStep = ({
  errors,
  formActiveStepId,
  getValues,
  isUserEventDetailInEditMode,
  register
}) => {
  const [isMobile] = useIsMobile();

  const getErrorClass = ({ key }) =>
    checkIsEmptyOrDummyEventDetail({
      key,
      value: getValues(key)
    })
      ? '!text-error-base'
      : '';

  const formStepVisibilityClass =
    formActiveStepId === EVENT_PROFILE_FORM_STEPS.HOST_PROFILE.id
      ? 'block'
      : 'hidden';

  return (
    <div
      className={`flex flex-col md:gap-4 gap-3 md:px-8 ${isMobile ? 'p-4' : ''} ${formStepVisibilityClass}`}
      id={EVENT_PROFILE_FORM_STEPS.HOST_PROFILE.id}
    >
      <InputField
        {...{
          alt: 'host name icon',
          className: `rounded-lg pl-10 ${getErrorClass({
            key: 'eventContactName'
          })}`,
          dbName: 'eventContactName',
          disabled: !isUserEventDetailInEditMode,
          errors,
          iconClass: isMobile ? 'h-10 place-content-center !top-0' : '',
          iconHeight: isMobile ? 20 : 24,
          iconWidth: isMobile ? 20 : 24,
          iconURL: isUserEventDetailInEditMode
            ? 'host-brand-v1-icon.svg'
            : 'host-gray-icon.svg',
          inputGroup: 'flex flex-col gap-2',
          label: 'Host Name *',
          labelClass: 'text-nero md:text-base text-sm font-medium',
          placeholder: 'Enter host name',
          register: { ...register('eventContactName') }
        }}
      />
      <EPMFormRow
        {...{
          inputProps: getCustomerTypeAndPhone({
            errors,
            isMobile,
            isUserEventDetailInEditMode,
            register
          })
        }}
      />

      <InputField
        {...{
          className: `rounded-lg pl-10 ${getErrorClass({
            key: 'eventContactEmail'
          })}`,
          dbName: 'eventContactEmail',
          disabled: !isUserEventDetailInEditMode,
          errors,
          iconClass: isMobile ? 'h-10 place-content-center !top-0' : '',
          iconHeight: isMobile ? 20 : 24,
          iconWidth: isMobile ? 20 : 24,
          iconURL: isUserEventDetailInEditMode
            ? 'email-brand-icon.svg'
            : 'email-black-icon.svg',
          inputGroup: 'flex flex-col gap-2',
          label: 'Host Email *',
          labelClass: 'text-nero md:text-base text-sm font-medium',
          placeholder: 'Enter Host email',
          register: { ...register('eventContactEmail') }
        }}
      />
      <InputField
        {...{
          className: 'rounded-lg pl-10',
          dbName: 'hostCreditEntity.name',
          disabled: true,
          errors,
          iconClass: isMobile ? 'h-10 place-content-center !top-0' : '',
          iconHeight: isMobile ? 20 : 24,
          iconWidth: isMobile ? 20 : 24,
          iconURL: isUserEventDetailInEditMode
            ? 'host-credit-entity-brand-icon.svg'
            : 'host-credit-entity-black-icon-v1.svg',
          inputGroup: 'flex flex-col gap-2',
          label: 'Credit Entity Name',
          labelClass: 'text-nero md:text-base text-sm font-medium',
          placeholder: 'Enter Credit Entity Name',
          register: { ...register('hostCreditEntity.name') }
        }}
      />
      <div className='flex md:flex-row flex-col md:w-1/2 w-full'>
        <InputField
          {...{
            className: 'rounded-lg pl-10',
            dbName: 'trnNumber',
            disabled: !isUserEventDetailInEditMode,
            errors,
            iconClass: isMobile ? 'h-10 place-content-center !top-0' : '',
            iconHeight: isMobile ? 20 : 24,
            iconWidth: isMobile ? 20 : 24,
            iconURL: isUserEventDetailInEditMode
              ? 'trn-brand-icon.svg'
              : 'trn-black-icon.svg',
            inputGroup: 'flex flex-col gap-2 flex-1',
            label: 'TRN',
            labelClass: 'text-nero md:text-base text-sm font-medium',
            placeholder: 'Enter TRN',
            register: { ...register('trnNumber') }
          }}
        />
      </div>
    </div>
  );
};

export default HostProfileStep;
