import { useRouter } from 'next/router';
import { useState } from 'react';

import {
  EPICBrief,
  EventCartListSideBarBody,
  EventCartListSideBarHead
} from '@/components/atomic/atoms';
import { Button } from '@/components/atomic/nuclei';
import { ALLOCATION_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';
import Image from 'next/image';

const EventCartListSidebar = ({
  activeModalsConfig,
  setActiveModal,
  setShowToast,
  userEvent
}) => {
  const [showInfo, setShowInfo] = useState(false);

  const { userEventDetails: [{ allocationStatus, userEventId } = {}] = [] } =
    userEvent || {};
  const router = useRouter();

  const [isMobile] = useIsMobile();

  const displayHostEventProfile = () => {
    router.push(
      getPageURL({
        excludeBaseUrl: false,
        pageName: PAGE_NAME.HOST_EVENT_PROFILE.label,
        pathParams: {
          userEventId
        }
      })
    );
  };

  const showEventCartListSideBarBody = isMobile ? showInfo : true;

  return (
    <div
      className={`relative md:w-108 md:px-6 bg-white py-4 md:pl-10 md:pr-10 pt-20 md:pt-5 flex flex-col gap-3 ${isMobile ? 'border-b-2 border-brand rounded-b-2xl pb-4 px-5' : ''}`}
    >
      <div className={`md:pt-5 flex flex-col md:gap-3 gap-2`}>
        <EventCartListSideBarHead
          {...{
            displayHostEventProfile,
            isMobile,
            userEvent
          }}
        />
        {showEventCartListSideBarBody && (
          <>
            <EventCartListSideBarBody
              {...{
                router,
                userEvent
              }}
            />
            <div className='md:mt-2 md:mb-4 border-t border-gray'></div>
            <EPICBrief
              {...{
                setShowToast,
                userEvent
              }}
            />
            <Button
              {...{
                className:
                  'border border-brand rounded-lg py-3 text-brand font-medium',
                disabled:
                  allocationStatus ===
                  ALLOCATION_STATUS.REASSIGN_REQUESTED.value,
                label: 'Request Reassign',
                onClick: () =>
                  setActiveModal({
                    key: activeModalsConfig.NEW_ASSIGNED_USER_EVENT_PREVIEW
                      .key,
                    value: userEvent
                  })
              }}
            />
          </>
        )}
      </div>
      {isMobile && (
        <Image
          {...{
            alt: 'show info',
            className: `absolute bottom-0 right-0 mr-7 translate-y-1/2 translate-x-1/2 cursor-pointer ${showInfo ? 'rotate-90' : '-rotate-90'}`,
            height: 0,
            onClick: () => setShowInfo(!showInfo),
            src: `${staticMediaStoreBaseURL}/icons/angel-icon.svg`,
            style: { height: 24, width: 24 },
            width: 0
          }}
        />
      )}
    </div>
  );
};

export default EventCartListSidebar;
