import {
  EPMFormRow,
  ShowFormattedAddress,
  TimePickerWithLabel
} from '@/components/atomic/atoms';
import { InputField, SelectTags, TextArea } from '@/components/atomic/nuclei';
import { TAG_OPTIONS_SELECTION_MODE } from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';
import { EVENT_PROFILE_FORM_STEPS } from '@/services/hostEvent.service';
import { checkIsEmptyOrDummyEventDetail } from '@/services/userEventCartList.service';

const getEventDuration = ({
  errors,
  isMobile,
  isUserEventDetailInEditMode,
  register
}) => [
  {
    className: 'rounded-lg pl-10',
    dbName: 'eventDurationInDays',
    disabled: !isUserEventDetailInEditMode,
    errors,
    iconClass: isMobile ? 'h-10 place-content-center !top-0' : '',
    iconHeight: isMobile ? 20 : 24,
    iconWidth: isMobile ? 20 : 24,
    iconURL: isUserEventDetailInEditMode
      ? 'time-icon-red.svg'
      : 'time-gray-icon.svg',
    inputGroup: 'flex flex-col gap-2 flex-1',
    label: 'Event Duration (in days)',
    labelClass: 'text-nero md:text-base text-sm font-medium',
    placeholder: 'Enter Event Duration (in days)',
    register: { ...register('eventDurationInDays') },
    type: 'number'
  }
];

const getBudgetAndOpportunity = ({
  errors,
  isMobile,
  isUserEventDetailInEditMode,
  register
}) => [
  {
    className: 'rounded-lg pl-10',
    dbName: 'budget',
    disabled: !isUserEventDetailInEditMode,
    errors,
    iconClass: isMobile ? 'h-10 place-content-center !top-0' : '',
    iconHeight: isMobile ? 20 : 24,
    iconWidth: isMobile ? 20 : 24,
    iconURL: isUserEventDetailInEditMode
      ? 'budget-brand-icon.svg'
      : 'budget-black-icon.svg',
    inputGroup: 'flex flex-col gap-2 flex-1',
    label: 'Budget',
    labelClass: 'text-nero md:text-base text-sm font-medium',
    placeholder: 'Enter Budget',
    register: { ...register('budget') }
  },
  {
    className: 'rounded-lg pl-10',
    dbName: 'opportunityValue',
    disabled: !isUserEventDetailInEditMode,
    errors,
    iconClass: isMobile ? 'h-10 place-content-center !top-0' : '',
    iconHeight: isMobile ? 20 : 24,
    iconWidth: isMobile ? 20 : 24,
    iconURL: isUserEventDetailInEditMode
      ? 'budget-brand-icon.svg'
      : 'budget-black-icon.svg',
    inputGroup: 'flex flex-col gap-2 flex-1',
    label: 'Opportunity Value',
    labelClass: 'text-nero md:text-base text-sm font-medium',
    placeholder: 'Enter Opportunity Value',
    register: { ...register('opportunityValue') }
  }
];

const getTheme = ({
  errors,
  isMobile,
  isUserEventDetailInEditMode,
  register
}) => [
  {
    className: 'rounded-lg pl-10',
    dbName: 'theme',
    disabled: !isUserEventDetailInEditMode,
    errors,
    iconClass: isMobile ? 'h-10 place-content-center !top-0' : '',
    iconHeight: isMobile ? 20 : 24,
    iconWidth: isMobile ? 20 : 24,
    iconURL: isUserEventDetailInEditMode
      ? 'theme-brand-icon.svg'
      : 'theme-black-icon.svg',
    inputGroup: 'flex flex-col gap-2 flex-1',
    label: 'Theme',
    labelClass: 'text-nero md:text-base text-sm font-medium',
    placeholder: 'Enter Theme',
    register: { ...register('theme') }
  }
];

const EventDeepDiveStep = ({
  contactMethods,
  errors,
  eventFormDetails,
  eventSiteTypeList,
  formActiveStepId,
  getValues,
  isUserEventDetailInEditMode,
  register,
  selectedEventContactMethod,
  setEventFormDetails,
  setValue
}) => {
  const [isMobile] = useIsMobile();

  const { selectedEventSiteType, selectedEventStartTime, userAddress } =
    eventFormDetails || {};

  const getErrorClass = ({ key }) =>
    checkIsEmptyOrDummyEventDetail({
      key,
      value: getValues(key)
    })
      ? '!text-error-base'
      : '';
  const formStepVisibilityClass =
    formActiveStepId === EVENT_PROFILE_FORM_STEPS.EVENT_DEEP_DIVE.id
      ? 'block'
      : 'hidden';

  return (
    <div
      className={`flex flex-col gap-4 md:px-8 ${isMobile ? 'p-4' : ''} ${formStepVisibilityClass}`}
      id={EVENT_PROFILE_FORM_STEPS.EVENT_DEEP_DIVE.id}
    >
      <SelectTags
        {...{
          containerStyle: 'flex flex-col gap-2 mt-1',
          formActiveStepId,
          isEditable: isUserEventDetailInEditMode,
          selectedTagOptions: selectedEventContactMethod,
          setSelectedTagOptions: (newContactMethod) =>
            setEventFormDetails((prevState) => ({
              ...prevState,
              selectedEventContactMethod: newContactMethod
            })),
          tagOptions: contactMethods,
          tagOptionsSelectionMode: TAG_OPTIONS_SELECTION_MODE.SINGLE,
          tagsFieldLabel: {
            label: 'How did you reach the customer?',
            labelStyle: 'text-nero md:text-base text-sm font-medium'
          }
        }}
      />
      <InputField
        {...{
          className: `rounded-lg pl-10 ${getErrorClass({ key: 'eventTitle' })}`,
          dbName: 'eventTitle',
          disabled: !isUserEventDetailInEditMode,
          errors,
          iconClass: isMobile ? 'h-10 place-content-center !top-0' : '',
          iconHeight: isMobile ? 20 : 24,
          iconWidth: isMobile ? 20 : 24,
          iconURL: isUserEventDetailInEditMode
            ? 'user-event-brand-icon.svg'
            : 'user-event-gray-icon.svg',
          inputGroup: 'flex flex-col gap-2',
          label: 'Event Title',
          labelClass: 'text-nero md:text-base text-sm font-medium',
          placeholder: 'Enter Event Title',
          register: { ...register('eventTitle') }
        }}
      />
      <ShowFormattedAddress
        {...{
          disabled: !isUserEventDetailInEditMode,
          errors,
          setShowAddAddress: (address) =>
            setEventFormDetails((prevState) => ({
              ...prevState,
              showAddAddress: address
            })),
          iconURL: isUserEventDetailInEditMode
            ? 'location-brand-icon.svg'
            : 'location-black-icon.svg',
          setValue,
          userAddress
        }}
      />
      <EPMFormRow
        {...{
          inputProps: getEventDuration({
            errors,
            isMobile,
            isUserEventDetailInEditMode,
            register
          })
        }}
      />

      <EPMFormRow
        {...{
          inputProps: getBudgetAndOpportunity({
            errors,
            isMobile,
            isUserEventDetailInEditMode,
            register
          })
        }}
      />

      <div className='flex flex-col md:flex-row gap-4 w-full'>
        <TimePickerWithLabel
          {...{
            errors,
            eventFormDetails,
            isUserEventDetailInEditMode,
            selectedEventStartTime,
            setEventFormDetails
          }}
        />
        <EPMFormRow
          {...{
            inputProps: getTheme({
              errors,
              isMobile,
              isUserEventDetailInEditMode,
              register
            })
          }}
        />
      </div>
      <SelectTags
        {...{
          containerStyle: 'flex flex-col gap-2',
          formActiveStepId,
          isEditable: isUserEventDetailInEditMode,
          selectedTagOptions: selectedEventSiteType,
          setSelectedTagOptions: (newSiteType) =>
            setEventFormDetails((prevState) => ({
              ...prevState,
              selectedEventSiteType: newSiteType
            })),
          tagOptions: eventSiteTypeList,
          tagOptionsSelectionMode: TAG_OPTIONS_SELECTION_MODE.SINGLE,
          tagsFieldLabel: {
            label: 'Event Site',
            labelStyle: 'text-nero md:text-base text-sm font-medium'
          }
        }}
      />
      <TextArea
        {...{
          className: 'rounded-lg pl-10 h-24',
          dbName: 'additionalDetails',
          disabled: !isUserEventDetailInEditMode,
          errors,
          iconURL: isUserEventDetailInEditMode
            ? 'solution-design-brand-icon.svg'
            : 'solution-design-black-icon.svg',
          inputGroup: 'flex flex-col gap-2 flex-1',
          label: 'Solution Design Notes',
          labelClass: 'text-nero md:text-base text-sm font-medium',
          placeholder: 'Enter Solution Design Notes',
          register: { ...register('additionalDetails') }
        }}
      />
    </div>
  );
};

export default EventDeepDiveStep;
