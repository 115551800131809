import Image from 'next/image';
import { useState } from 'react';

import { EPICBrief } from '@/components/atomic/atoms';
import {
  CloseIcon,
  Text,
  TextKeyValuePair,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import {
  ALLOCATION_STATUS,
  mediaStoreBaseURL,
  staticMediaStoreBaseURL,
  USER_EVENT_STAGE
} from '@/config/common';
import usePageExit from '@/hooks/usePageExit';
import { useIsMobile } from '@/lib/screenResolution';
import { parseNumberedLabel } from '@/lib/utils';
import {
  getLabelForEventRequestIsEndToEnd,
  getZendeskTicketPriorityConfig
} from '@/services/userEventCartList.service';

const EventProfileSidebarHead = ({
  checkoutEvent,
  eventRequestIsEndToEnd,
  isMobile,
  showIsEventRequestEndToEndLabel
}) => (
  <div className='flex md:gap-4 gap-3 items-center md:mt-4'>
    <Image
      alt='Event'
      className='rounded-lg'
      height={0}
      priority={true}
      src={`${mediaStoreBaseURL}/${checkoutEvent?.iconUrl}`}
      style={{
        height: isMobile ? 64 : 100,
        width: isMobile ? 64 : 100
      }}
      unoptimized={true}
      width={0}
    />
    <div className='flex w-60 flex-col gap-1'>
      <Text
        {...{
          className:
            'text-nero md:text-xl text-base font-semibold text-white truncate-text',
          content: checkoutEvent?.event.name
        }}
      />
      {showIsEventRequestEndToEndLabel && (
        <Text
          {...{
            className: 'md:text-base text-xs font-medium text-white',
            content: getLabelForEventRequestIsEndToEnd(eventRequestIsEndToEnd)
          }}
        />
      )}
    </div>
  </div>
);

const HostEventProfileSidebar = ({
  eventRequestIsEndToEnd,
  setShowToast,
  userEventInformation
}) => {
  const [showInfo, setShowInfo] = useState(false);

  const [isMobile] = useIsMobile();

  const {
    userEventDetails: [
      { allocationStatus, checkoutEvent = {}, opsUser = {} } = {}
    ] = [],
    stage,
    userEventNumber,
    zendeskTicketDetail
  } = userEventInformation;
  const { priority } = zendeskTicketDetail || {};
  const showIsEventRequestEndToEndLabel = eventRequestIsEndToEnd !== null;
  const zendeskTicketPriorityConfig = getZendeskTicketPriorityConfig(priority);

  const tooltipConfigList = [
    {
      target: 'event-stage',
      text: 'Event Stage'
    },
    {
      target: 'event-planner-name',
      text: 'Event Planner Name'
    }
  ];

  const showEventCartListSideBarBody = isMobile ? showInfo : true;

  const onClickExit = usePageExit();

  return (
    <div
      className={`relative md:w-108 md:fixed md:h-screen z-30 bg-host-event-sidebar bg-no-repeat bg-cover px-6 py-4 md:pl-10 md:pr-10 md:pt-5 flex flex-col gap-3 ${isMobile ? 'border-b-2 border-brand rounded-b-2xl pb-4 px-5' : ''}`}
    >
      <div className='flex items-center justify-between block h-12'>
        <Image
          alt='hafla-logo'
          height={0}
          priority={true}
          src={`${staticMediaStoreBaseURL}/icons/hafla-white-logo.svg`}
          style={{
            height: isMobile ? 24 : 40,
            width: isMobile ? 70 : 125
          }}
          width={0}
        />
        {isMobile && (
          <CloseIcon
            {...{
              height: 24,
              icon: 'close-white-icon.svg',
              onClick: onClickExit,
              width: 24
            }}
          />
        )}
      </div>
      <EventProfileSidebarHead
        {...{
          checkoutEvent,
          eventRequestIsEndToEnd,
          isMobile,
          showIsEventRequestEndToEndLabel
        }}
      />
      {showEventCartListSideBarBody && (
        <>
          <div className='flex justify-between'>
            <div>
              <Text
                {...{
                  className: `${USER_EVENT_STAGE[stage].class} rounded md:rounded-lg px-2 py-1 md:text-sm text-xs font-medium`,
                  content: USER_EVENT_STAGE[stage].label,
                  id: 'event-stage'
                }}
              />
            </div>
            <div className='flex gap-2'>
              <TextWithIcon
                {...{
                  className: `${zendeskTicketPriorityConfig.className} px-2 py-1`,
                  icon: zendeskTicketPriorityConfig.icon,
                  label: priority,
                  labelStyle: 'md:text-sm text-xs font-medium',
                  show: Boolean(priority)
                }}
              />
              <TextWithIcon
                {...{
                  className: 'bg-white md:rounded-lg rounded px-2 py-1',
                  icon: 'user-event-brand-icon.svg',
                  id: 'event-number',
                  label: parseNumberedLabel(userEventNumber),
                  labelStyle: 'md:text-sm text-xs font-medium text-dim-gray',
                  onHoverCopy: true,
                  tooltipText: 'Event Number'
                }}
              />
            </div>
          </div>
          <div className='flex justify-start gap-4 mt-2'>
            <div className='flex md:gap-3 gap-2 items-center'>
              <Image
                alt='event planner white icon'
                className='rounded-lg'
                height={0}
                priority={true}
                src={`${staticMediaStoreBaseURL}/icons/planner-white-icon-v2.svg`}
                style={{
                  height: isMobile ? 36 : 48,
                  width: isMobile ? 36 : 48
                }}
                width={0}
              />
              <TextKeyValuePair
                {...{
                  className: 'flex-col !gap-0',
                  label: opsUser?.name || 'NA',
                  labelClass: 'text-white font-semibold md:text-lg text-base',
                  labelId: 'event-planner-name',
                  spaceTop: '',
                  value: ALLOCATION_STATUS[allocationStatus].label,
                  valueClassName: `md:text-sm text-[10px] font-medium text-white px-2 rounded py-[2px] w-fit ${ALLOCATION_STATUS[allocationStatus]?.className}`
                }}
              />
            </div>
          </div>
          <div className='my-2 border-t border-opacity-color'></div>
          <EPICBrief
            {...{
              containerStyle: 'event-progress-bar-content',
              setShowToast,
              userEvent: userEventInformation
            }}
          />
          <Tooltip
            {...{
              configList: tooltipConfigList
            }}
          />
        </>
      )}
      {isMobile && (
        <Image
          {...{
            alt: 'show info',
            className: `absolute bottom-0 right-0 mr-7 translate-y-1/2 translate-x-1/2 cursor-pointer ${showInfo ? 'rotate-180' : ''}`,
            height: 0,
            onClick: () => setShowInfo(!showInfo),
            src: `${staticMediaStoreBaseURL}/icons/expand-white-icon.svg`,
            style: { height: 24, width: 24 },
            width: 0
          }}
        />
      )}
    </div>
  );
};

export default HostEventProfileSidebar;
